var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "row",
          expression: "widget.compType === 'row'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_operation_status") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("lang_please_select"),
                  clearable: "",
                },
                model: {
                  value: _vm.widget.properties.operation,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "operation", $$v)
                  },
                  expression: "widget.properties.operation",
                },
              },
              _vm._l(_vm.statusList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.label, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c("el-divider", [_vm._v(_vm._s(_vm.$t("lang_column_configuration")))]),
        _vm._l(_vm.widget.properties.gridColumns, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "select-item" },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_column_1") + (index + 1) } },
                [
                  _c("el-input-number", {
                    attrs: { size: "mini", min: 1, max: 24 },
                    model: {
                      value: item.span,
                      callback: function ($$v) {
                        _vm.$set(item, "span", $$v)
                      },
                      expression: "item.span",
                    },
                  }),
                  _c("i", {
                    staticClass: "el-icon-remove-outline close-btn",
                    staticStyle: {
                      "font-size": "var(--rootFontSize2)",
                      "margin-left": "10px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.removeColumn(index)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        _c(
          "div",
          { staticStyle: { width: "100%", "text-align": "center" } },
          [
            _c(
              "el-button",
              {
                staticStyle: { "padding-bottom": "0" },
                attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
                on: { click: _vm.addColumn },
              },
              [_vm._v(" " + _vm._s(_vm.$t("lang_click_add_column")) + " ")]
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }