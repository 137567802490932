


































import { Component, Mixins } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';

/**
 * input的配置项
 */
@Component({
  name: 'GridConfig',
  components: {}
})
export default class extends Mixins(ElePropertiesMixins) {
  addColumn() {
    let gridColumns: any = this.widget.properties!.gridColumns;
    if (!gridColumns) {
      gridColumns = [];
    }
    let usedSpans = 0;
    gridColumns.forEach(col => {
      usedSpans += col.span;
    });
    if (usedSpans < 24) {
      const colIndex = gridColumns.length;
      let avaliableSpan = 24 - usedSpans;
      let newColumn = {
        index: colIndex,
        span: avaliableSpan,
        components: []
      };
      gridColumns.push(newColumn);
    } else {
      this.$message.warning(this.$t('lang_grid_length_exceeded') as string);
    }
  }
  removeColumn(index) {
    this.widget!.properties!.gridColumns!.splice(index, 1);
  }
}
